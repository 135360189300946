<template>
    <section class="content">
        <div class="content__inner content__inner--sm">
            <header class="content__title">
                <h1>Settings</h1>
                <div class="actions"></div>
            </header>
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            News Categories
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="addCategory">
                                <label for="">Enter Category Name</label>
                                <div class="category-wrapper">
                                    <input type="text" class="form-control" v-model="category" required>
                                    <button class="btn btn-success" type="submit" :disabled="isAdding">
                                        <i class="zmdi zmdi-save"></i>
                                        <span v-if="isAdding"></span>
                                    </button>
                                </div>
                            </form>
                            <processing v-if="isLoading" />
                            <ul class="category-list" v-else-if="categories.length > 0">
                                <li v-for="(category, i) in categories" :key="i">
                                    {{ category }}
                                    <i class="zmdi zmdi-close" @click="remove(category, i)"></i>
                                </li>
                            </ul>
                            <div v-else>No categories found</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            Change Password
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="reset">
                                <div class="form-group">
                                    <label for="">Current Email</label>
                                    <input type="text" class="form-control" v-model="currentEmail" required>
                                </div>
                                <button class="btn btn-danger btn-block" type="submit" :disabled="isSending">
                                    Change Password
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.category-wrapper {
    display: flex;
    margin-bottom: 20px;
}
.category-wrapper button {
    margin-left: 20px;
}
.category-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.category-list li {
    padding: 5px 0;
    position: relative;
}
.category-list li i {
    position: absolute;
    right: 0px;
    top: 5px;
    font-size: 14px;
    cursor: pointer;
    padding: 5px;
}
</style>

<script>
export default {
    data(){
        return {
            category: "",
            categories: [],
            isAdding: false,
            isLoading: false,
            isSending: false,
            currentEmail: "",
        }
    },
    created(){
        this.getCategories()
    },
    methods: {
        async addCategory(){
            if (this.category != "") {
                this.isAdding = true
                const addCategory = this.$fn.httpsCallable("addCategory")
                const response = await addCategory({ category: this.category })
                this.categories.unshift(this.category)
                this.$toast[response.data.code == 200 ? 'success' : 'error'](response.data.message)
                if (response.data.code == 200) {
                    this.category = ""
                }
                this.isAdding = false
            } else {
                this.$toast.error("Category name cannot be empty")
            }
        },
        async getCategories(){
            this.isLoading = true
            const getCategories = this.$fn.httpsCallable("getCategories")
            const response = await getCategories()
            this.categories = response.data.categories
            this.isLoading = false
        },
        async remove(category, i){
            this.categories.splice(i, 1)
            const deleteCategory = this.$fn.httpsCallable("deleteCategory")
            await deleteCategory({ category })
        },
        async reset(){
            const auth = this.$fb.auth()
            const emailAddress = this.currentEmail
            try {
                this.isSending = true
                await auth.sendPasswordResetEmail(emailAddress)
                this.$toast.success("Password reset email sent to " + emailAddress + " successfully.")
                this.currentEmail = ""
                this.isSending = false
            } catch (error) {
                this.$toast.error(error.message)
                this.isSending = false
            }
        },
    },
    components: {
        "processing": () => import("@/components/Loaders/Processing.vue")
    }
}
</script>